import "./css/common.css";
import "./css/footer.css";
import "./css/nav.css";
import "./css/team.css";
import "./css/welcome.css";
import "./css/contact.css";
import "./css/access.css";
import "./css/responsive.css";

const MOBILE_HACK = 500;
let progress = 0;
let phase = 0;
let prevPhase = 0;
let viewBB, circle, circleCTX, propTimelines, num1, num2, num3, num4;
let findOut = false;

let isMobile = window.innerWidth <= 900; //kludge to account for mobile browsers that hide/show their chrome on scroll
var pHidden = true;

function router() {
  const previewWidth = document.querySelector(".preview-omnibar").offsetWidth;
  const previewHeight = document.querySelector(".preview-omnibar").offsetHeight;

  // console.log(previewWidth, previewHeight);

  // document.querySelector(".preview-omnibar-text").style.marginBottom =
  // previewHeight / 2 + previewHeight / 15 + "px";

  // document.querySelector(".preview-omnibar-text").style.marginLeft =
  // previewWidth / 2 + "px";

  var typed = new Typed(".preview-omnibar-text", {
    loop: true,
    showCursor: false,
    strings: [
      "Start a vacation planning project for a trip to Marfa",
      "Compare my Jira and GitHub issues and prioritize my todo list",
      "Show me a timeline of all my research on dog breeds",
      "How much time is explicitly spent on supporting my OKRs",
      "Create a folder that organizes all my wedding photos",
      "Add highlights to Workday for the quarter review",
      "How do I use the genetic engineering kit at https://the-odin.com",
      "Review my last commits and let me know if this fixes the packet loss",
    ],
    typeSpeed: 100,
  });

  switch (window.location.pathname) {
    case "/":
      // window.location = "/";
      break;

    default:
      document.body.style.overflowY = "scroll";

      document.querySelectorAll(".active").forEach(function (it) {
        it.classList.remove("active");
      });

      // e.target.classList.add("active");

      document.querySelectorAll(".page").forEach(function (page) {
        page.classList.add("hide");
      });

      let c = document.querySelector(p);

      if (c) {
        c.classList.remove("hide");
      }

      document.body.scrollTop = 0;
  }

  var isOpen = false;
  window.addEventListener("click", function (e) {
    if (isOpen) {
      if (
        !document.querySelector(".nav-container").contains(e.target) &&
        !document.getElementById("kaba-nav-items").contains(e.target)
      ) {
        document.getElementById("button-home").classList.add("hide");
        document.querySelector(".nav-container").classList.remove("open");
        isOpen = false;
        document.body.style.overflowY = "scroll";
        return false;
      }
    } else {
      document.getElementById("button-home").classList.add("hide");
      document.querySelector(".nav-container").classList.remove("open");
      isOpen = false;
      return false;
    }
  });

  document.querySelector(".home").addEventListener("click", function (e) {
    // e.preventDefault();
    // e.stopPropagation();

    if (window.innerWidth <= 500) {
      if (isOpen) {
        document.getElementById("button-home").classList.add("hide");
        document.querySelector(".nav-container").classList.remove("open");
        isOpen = false;
        document.body.style.overflowY = "scroll";
        return false;
      }

      document.getElementById("button-home").classList.remove("hide");
      document.querySelector(".nav-container").classList.add("open");
      isOpen = true;
      document.body.style.overflowY = "none";

      return false;
    } else {
      window.location = "/";
    }
  });

  document
    .getElementById("no-more-secrets")
    .addEventListener("click", function (e) {
      document.querySelector(".pane").style.display = pHidden
        ? "block"
        : "none";
      document.querySelector("nav").style.display = !pHidden ? "flex" : "none";
      document.querySelector("#welcome").style.display = !pHidden
        ? "block"
        : "none";
      document.querySelector("#who-we-are").style.display = !pHidden
        ? "flex"
        : "none";
      document.querySelector("#contact").style.display = !pHidden
        ? "grid"
        : "none";
      document.querySelector("#request").style.display = !pHidden
        ? "grid"
        : "none";
      document.querySelector("footer").style.display = !pHidden
        ? "flex"
        : "none";
      pHidden = !pHidden;
    });

  document.querySelectorAll(".data-link").forEach(function (b) {
    b.addEventListener("click", function (e, item) {
      // e.preventDefault();
      // e.stopPropagation();

      document.querySelector(".nav-container").classList.remove("open");

      const p = e.target.dataset.href;

      // if (p === "#/" || p === "/") {
      // window.location = "/";
      // return;
      // }

      window.history.pushState(p, "", p);

      if (p === "/" || p === "#/") {
        window.location = "/";
        return;
      } else {
        document.getElementById("button-home").classList.add("hide");
        document.querySelector(".nav-container").classList.remove("open");
        isOpen = false;
        document.body.style.overflowY = "scroll";

        document.querySelectorAll(".active").forEach(function (it) {
          it.classList.remove("active");
        });

        e.target.classList.add("active");

        document.querySelectorAll(".page").forEach(function (page) {
          page.classList.add("hide");
        });

        document.querySelector(p).classList.remove("hide");

        document.body.scrollTop = 0;
      }
    });
  });
}

window.addEventListener("load", () => {
  num1 = document.querySelector("#vp-n-1");
  num2 = document.querySelector("#vp-n-2");
  num3 = document.querySelector("#vp-n-3");
  num4 = document.querySelector("#vp-n-4");

  var realCheck = function (e) {
    const real = e.querySelector(".real-input input");
    return real && real.value !== "" ? false : true;
  };

  gsap.to(document.querySelector(".hippo-loading"), {
    scale: 0.85,
    opacity: 0.5,
    duration: 1,
    ease: "bounce.out",
    repeat: -1,
    yoyo: true,
  });

  var sendSubmit = function (params) {
    let name = params.querySelector("#kaba-request-name");
    let email =
      params.querySelector("#kaba-request-email") ||
      params.querySelector("#kaba-footer-form-email");
    let more = params.querySelector("#kaba-request-body");

    let body = {
      subscription: {
        name: name ? name.value : "",
        email: email ? email.value : "",
        body: more ? more.value : "",
      },
    };

    fetch("https://api.kaba.ai/subscriptions", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    });

    document.body.style.overflow = "hidden";
  };

  const form = document.getElementById("kaba-request-access-form");
  form.addEventListener("submit", function (e) {
    e.preventDefault();

    let f = document.getElementById("request-access-form");
    let done = document.getElementById("request-access-done");

    done.classList.remove("hide");
    f.classList.add("hide");
    done.classList.add("animated", "bounceIn");
    done.style.setProperty("--animate-duration", "1s");
    // form.submit();
    //
    if (realCheck(f)) {
      sendSubmit(form);
    }
  });

  const footerForm = document.querySelector("#kaba-footer-form");
  footerForm.addEventListener("submit", function (e) {
    e.preventDefault();
    e.stopPropagation();

    let f = document.querySelector(".signup");
    let done = document.querySelector(".sign-up-confirm");

    done.style.display = "flex";
    f.style.display = "none";
    done.classList.add("animated", "bounceIn");
    done.style.setProperty("--animate-duration", "1s");

    if (realCheck(f)) {
      sendSubmit(footerForm);
    }

    return false;
  });
  // document
  // .getElementById("footer-submit-form")
  // .addEventListener("click", function (e) {
  // e.preventDefault();
  // footerForm.submit();
  // return false;
  // });

  // invoke the router
  router();

  viewBB = document.querySelector("#s-value-props").getBoundingClientRect();
  circle = document.querySelector(".circle");
  circleCTX = getPixelDensityCorrectedContext(circle);
  renderCircle();

  document.body.addEventListener(
    "scroll",
    (e) => {
      const t = e.target.scrollTop;
      const st = Math.min(
        Math.max(0, e.target.scrollTop),
        e.target.scrollHeight
      );
      prevPhase = phase;
      phase = Math.floor(st / window.innerHeight);
      progress = (st / window.innerHeight) % 1;
      handleScroll();
    },
    false
  );

  var isResizing = false;
  var resizeTimeout;
  var reloadPageTimeout = function () {
    window.location = "/";
    isResizing = false;
  };

  window.addEventListener("resize", () => {
    isMobile = window.innerWidth <= 900;

    if (isMobile) return;

    isResizing = true;
    viewBB = document.querySelector("#s-value-props").getBoundingClientRect();

    if (resizeTimeout) {
      clearTimeout(resizeTimeout);
    }

    resizeTimeout = setTimeout(reloadPageTimeout, 100);
    // updateUI();
    // renderCircle();
  });

  // new ResizeObserver(function () {
  // console.log("hello?");
  // viewBB = document.querySelector("#s-value-props").getBoundingClientRect();
  // }).observe("body");

  document.querySelector("#btn-find-out").addEventListener("click", () => {
    findOut = true;
    const dur = 0.85;
    document.querySelector("#s-one").style.position = "fixed";

    gsap.to(document.querySelector("#s-one"), {
      y: "-100vh",
      duration: dur * 0.8,
    });
    gsap.to(document.querySelector("#s-two"), {
      opacity: 1,
      duration: dur,
      delay: dur * 0.2,
    });
    gsap.to(document.querySelector("#s-two"), {
      scale: 1,
      duration: dur * 0.8,
      delay: dur * 0.2,
    });
  });

  document.querySelector("#btn-scroll-down").addEventListener("click", () => {
    // const dur = 0.85;
    // document.body.style.overflowY = "scroll";
    // document.querySelector("#s-two").style.zIndex = 0;
    // gsap.to(document.querySelector("#s-two"), {
    // y: "-110vh",
    // duration: dur * 0.8,
    // });
    // gsap.to(
    // document.querySelector("#s-value-props"),
    // {
    // y: "-10px",
    // opacity: 1,
    // duration: dur,
    // delay: dur * 0.2,
    // },
    // function () {
    // updateUI();
    // renderCircle();
    // }
    // );
  });

  buildTimelines();
});

const handleScroll = () => {
  // console.log(`phase-${phase}, progress-${progress.toFixed(1)}`);
  updateUI();
  renderCircle();
};

const updateUI = () => {
  const tOut = 250;
  if (findOut) {
    document.querySelector("#s-two").style.opacity =
      phase > 0 || (phase == 0 && progress > 0.8)
        ? 0
        : progress < 0.5
          ? 1
          : 1 - remap(progress, 0.5, 0.8, 0, 1);
  } else {
    if (phase == 0) {
      document.querySelector("#s-two").style.opacity = progress;
      document.querySelector("#s-two").style.transform =
        `scale(${remap(progress, 0, 1, 0.25, 1)})`;
    } else if (phase == 1 && progress < 0.8) {
      document.querySelector("#s-two").style.opacity =
        1 - remap(progress, 0, 0.8, 0, 1);
    } else {
      document.querySelector("#s-two").style.opacity = 0;
    }
  }

  if (document.querySelector("#s-two").style.opacity === "0") {
    document.querySelector("#s-two").style.display = "none";
  } else {
    document.querySelector("#s-two").style.display = "flex";
  }

  document.querySelector("#s-value-props").style.position =
    phase > (findOut ? 0 : 1) ? "fixed" : "relative";

  document.querySelector("#s-value-props").style.display =
    phase > (findOut ? 0 : 1) ? "flex" : "none";

  document.querySelector("#s-value-props").style.opacity =
    phase > (findOut ? 5 : 6)
      ? 0
      : phase > (findOut ? 4 : 5)
        ? 1 - progress * 3
        : 1;

  if (document.querySelector("#s-value-props").style.opacity === "0") {
    document.querySelector("#s-value-props").style.display = "none";
  } else {
    document.querySelector("#s-value-props").style.display = "flex";
  }

  const offset = findOut ? 0 : 1;
  if (phase < 5 + offset) {
    if (prevPhase < phase) {
      if (prevPhase > 0 + offset) {
        propTimelines[prevPhase - offset - 1].reverse();
        window.setTimeout(() => {
          let p = propTimelines[phase - offset - 1];
          if (p) {
            p.play();
          }
        }, tOut);
      } else if (phase > offset) {
        let p = propTimelines[phase - offset - 1];
        if (p) {
          p.play();
        }
      }
    } else if (prevPhase > phase) {
      if (prevPhase < 5 + offset) {
        propTimelines[prevPhase - offset - 1].reverse();
        window.setTimeout(() => {
          if (phase > 0 + offset) {
            let p = propTimelines[phase - offset - 1];
            if (p) {
              p.play();
            }
          }
        }, tOut);
      } else if (phase > offset) {
        if (phase > 0 + offset) {
          let p = propTimelines[phase - offset - 1];
          if (p) {
            p.play();
          }
        }
      }
    }
  }
};

const renderCircle = () => {
  if (window.innerWidth <= MOBILE_HACK) {
    renderMobileCircle();
  } else {
    renderDesktopCircle();
  }
};

const renderMobileCircle = () => {
  const radius = viewBB.width * 0.05;
  const vOffset = viewBB.height * 0.2;
  const nBB = num1.getBoundingClientRect();
  num1.style.opacity = phase == (findOut ? 0 : 1) + 1 ? 1 : 0;
  num1.style.color = "#DEEB39";
  num1.style.left = `${viewBB.width * 0.5 - nBB.width * 0.5}px`;
  num1.style.top = `${vOffset - nBB.height * 0.5}px`;
  num2.style.opacity = phase == (findOut ? 0 : 1) + 2 ? 1 : 0;
  num2.style.color = "#DEEB39";
  num2.style.left = num1.style.left;
  num2.style.top = num1.style.top;
  num3.style.opacity = phase == (findOut ? 0 : 1) + 3 ? 1 : 0;
  num3.style.color = "#DEEB39";
  num3.style.left = num1.style.left;
  num3.style.top = num1.style.top;
  num4.style.opacity = phase == (findOut ? 0 : 1) + 4 ? 1 : 0;
  num4.style.color = "#DEEB39";
  num4.style.left = num1.style.left;
  num4.style.top = num1.style.top;

  circleCTX.clearRect(0, 0, circle.width, circle.height);
  circleCTX.lineWidth = 1;
  circleCTX.strokeStyle = "#ffffff";
  circleCTX.beginPath();
  circleCTX.arc(viewBB.width * 0.5, vOffset, radius, 0, 2 * Math.PI);
  circleCTX.stroke();
  if (phase > (findOut ? 0 : 1)) {
    circleCTX.lineWidth = 2;
    circleCTX.strokeStyle = "#DEEB39";
    circleCTX.beginPath();
    circleCTX.arc(
      viewBB.width * 0.5,
      vOffset,
      radius,
      -0.5 * Math.PI,
      Math.min(
        -0.5 * Math.PI +
          progress * 0.5 * Math.PI +
          (phase - (findOut ? 0 : 1) - 1) * 0.5 * Math.PI
      )
    );
    circleCTX.stroke();
  }
};

const renderDesktopCircle = () => {
  const radius =
    (viewBB.height < viewBB.width ? viewBB.height : viewBB.width) * 0.35;
  const nBB = num1.getBoundingClientRect();
  num1.style.color = phase == (findOut ? 0 : 1) + 1 ? "#DEEB39" : "#ffffff";
  num1.style.left = `${viewBB.width * 0.5 - nBB.width * 0.5}px`;
  num1.style.top = `${viewBB.height * 0.5 - nBB.height * 0.5 - radius}px`;
  num2.style.color = phase == (findOut ? 0 : 1) + 2 ? "#DEEB39" : "#ffffff";
  num2.style.left = `${viewBB.width * 0.5 - nBB.width * 0.5 + radius}px`;
  num2.style.top = `${viewBB.height * 0.5 - nBB.height * 0.5}px`;
  num3.style.color = phase == (findOut ? 0 : 1) + 3 ? "#DEEB39" : "#ffffff";
  num3.style.left = `${viewBB.width * 0.5 - nBB.width * 0.5}px`;
  num3.style.top = `${viewBB.height * 0.5 - nBB.height * 0.5 + radius}px`;
  num4.style.color = phase == (findOut ? 0 : 1) + 4 ? "#DEEB39" : "#ffffff";
  num4.style.left = `${viewBB.width * 0.5 - nBB.width * 0.5 - radius}px`;
  num4.style.top = `${viewBB.height * 0.5 - nBB.height * 0.5}px`;

  circleCTX.clearRect(0, 0, circle.width, circle.height);
  circleCTX.lineWidth = 1;
  circleCTX.strokeStyle = "#ffffff";
  circleCTX.beginPath();
  circleCTX.arc(
    viewBB.width * 0.5,
    viewBB.height * 0.5,
    radius,
    0,
    2 * Math.PI
  );
  circleCTX.stroke();
  if (phase > (findOut ? 0 : 1)) {
    circleCTX.lineWidth = 2;
    circleCTX.strokeStyle = "#DEEB39";
    circleCTX.beginPath();
    circleCTX.arc(
      viewBB.width * 0.5,
      viewBB.height * 0.5,
      radius,
      -0.5 * Math.PI,
      Math.min(
        Math.PI,
        0.5 * Math.PI * (phase - (findOut ? 0 : 1) - 2) +
          progress * 0.5 * Math.PI
      )
    );
    circleCTX.stroke();
    if (phase > (findOut ? 0 : 1) + 2) {
      circleCTX.beginPath();
      circleCTX.arc(
        viewBB.width * 0.5,
        viewBB.height * 0.5,
        radius,
        -0.5 * Math.PI,
        -0.5 * Math.PI -
          Math.min(
            0.5 * Math.PI,
            0.5 * Math.PI * (phase - (findOut ? 0 : 1) - 3) +
              progress * 0.5 * Math.PI
          ),
        true
      );
      circleCTX.stroke();
    }
  }
};

const buildTimelines = () => {
  const dur = 0.26;
  const rot = -Math.PI * 4;
  const sc = 0.95;
  propTimelines = [];
  var tl = gsap.timeline();
  tl.fromTo(
    document.querySelector("#s-value-prop-1"),
    { opacity: 0, display: "none" },
    { opacity: 1, duration: dur, display: "flex" },
    0
  );
  // tl.fromTo(document.querySelector("#s-value-prop-1"), {rotateZ: rot}, {rotateZ: 0, duration: dur}, 0);
  tl.fromTo(
    document.querySelector("#s-value-prop-1"),
    { scale: sc },
    { scale: 1, duration: dur },
    0
  );
  propTimelines.push(tl);
  tl = gsap.timeline();
  tl.fromTo(
    document.querySelector("#s-value-prop-2"),
    { opacity: 0, display: "none" },
    { opacity: 1, duration: dur, display: "flex" },
    0
  );
  // tl.fromTo(document.querySelector("#s-value-prop-2"), {rotateZ: rot}, {rotateZ: 0, duration: dur}, 0);
  tl.fromTo(
    document.querySelector("#s-value-prop-2"),
    { scale: sc },
    { scale: 1, duration: dur },
    0
  );
  propTimelines.push(tl);
  tl = gsap.timeline();
  tl.fromTo(
    document.querySelector("#s-value-prop-3"),
    { opacity: 0, display: "none" },
    { opacity: 1, duration: dur, display: "flex" },
    0
  );
  // tl.fromTo(document.querySelector("#s-value-prop-3"), {rotateZ: rot}, {rotateZ: 0, duration: dur}, 0);
  tl.fromTo(
    document.querySelector("#s-value-prop-3"),
    { scale: sc },
    { scale: 1, duration: dur },
    0
  );
  propTimelines.push(tl);
  tl = gsap.timeline();
  tl.fromTo(
    document.querySelector("#s-value-prop-4"),
    { opacity: 0, display: "none" },
    { opacity: 1, duration: dur, display: "flex" },
    0
  );
  // tl.fromTo(document.querySelector("#s-value-prop-4"), {rotateZ: rot}, {rotateZ: 0, duration: dur}, 0);
  tl.fromTo(
    document.querySelector("#s-value-prop-4"),
    { scale: sc },
    { scale: 1, duration: dur },
    0
  );
  propTimelines.push(tl);
  window.setTimeout(() => {
    propTimelines[0].reverse();
    propTimelines[1].reverse();
    propTimelines[2].reverse();
    propTimelines[3].reverse();
  }, 150);
};

const getPixelDensityCorrectedContext = (canvas) => {
  let pixelRatio = window.devicePixelRatio;
  let sizeOnScreen = canvas.getBoundingClientRect();
  canvas.width = sizeOnScreen.width * pixelRatio;
  canvas.height = sizeOnScreen.height * pixelRatio;
  canvas.style.width = canvas.width / pixelRatio + "px";
  canvas.style.height = canvas.height / pixelRatio + "px";
  let context = canvas.getContext("2d");
  context.scale(pixelRatio, pixelRatio);

  return context;
};

function remap(number, inMin, inMax, outMin, outMax) {
  return ((number - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
}

module.exports = { pHidden };
